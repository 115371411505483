//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _registerMainServiceWorker from './registerMainServiceWorker';
import _registerRedirectWorker from './registerRedirectWorker';
export const registerMainServiceWorker = _registerMainServiceWorker;
export const registerRedirectWorker = _registerRedirectWorker;
export default {registerMainServiceWorker, registerRedirectWorker};

