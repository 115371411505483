import { createElement as rc, useCallback, useRef, useState } from 'react';
import styled from '../styled';
import fromTheme from '../fromTheme';
import PropTypes from 'prop-types';
import View from './View';
import Text from './Text';
import TextInput from './TextInput';

import { FileDrop } from 'react-file-drop';

const getBorderColor = props => {
    const { theme } = props;
    if (props.isActive) {
        return theme.activeBorderColor;
    }
    return theme.borderColor;
};

const Container = styled(View).attrs({ name: 'drop-zone' })`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 6px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getBorderColor(props)};
    border-style: dashed;
    background-color: ${fromTheme('backgroundColorLighter')};
    outline: none;
    transition: border 0.24s ease-in-out;
`;

const FileInput = styled(TextInput)`
    display: none;
`;

const HintText = styled(Text)`
    color: ${fromTheme('disabledFontColor')};
`;

/*
    `mimeTypes`: is an array of strings. 
    Either mime types, or file extensions (e.g. .csv)
    The green border will only show up in case of mime types

    `onFileSelected`: Make sure to use useCallback:
    -------------------------------------------------
    const onFileSelected = useCallback(acceptedFiles => {
        // Do something with the files
    }, [])

    rc(DropZone,{onFileSelected})
    -------------------------------------------------
*/
const DropZone = props => {
    const { id, title, mimeTypes = [], allowMultiple = false, onFileSelected, onInvalidFileSelected } = props || {};
    const [value, setValue] = useState('');
    const [isActive, setActive] = useState(false);
    const activate = useCallback(() => {
        if (!isActive) setActive(true);
    }, [isActive]);
    const deactivate = useCallback(() => {
        if (isActive) setActive(false);
    }, [isActive]);

    const fileInputRef = useRef(null);
    const onTargetClick = () => {
        fileInputRef.current.click();
    };

    const onFileDropped = useCallback(
        /**
         *
         * @param {FileList} files Enumerable, but not an Array
         */
        files => {
            const [acceptedFiles, errors] = [...files].reduce(
                ([acceptedSoFar, errorsSoFar], file) => {
                    const fileName = file.name;
                    const filetype = file.type;
                    const extension = fileName.substring(fileName.lastIndexOf('.'));
                    if (
                        mimeTypes.includes('*.*') ||
                        mimeTypes.some(ext => ext.includes(extension) || ext === filetype)
                    ) {
                        return [[...acceptedSoFar, file], errorsSoFar];
                    } else {
                        const newError = {
                            message: `${fileName} has an invalid file type, please select a file of type ${mimeTypes.join(
                                ','
                            )}`
                        };
                        return [acceptedSoFar, [...errorsSoFar, newError]];
                    }
                },
                [[], []]
            );

            if (acceptedFiles.length) {
                onFileSelected(acceptedFiles);
            }
            if (errors.length) {
                onInvalidFileSelected(errors);
            }
            setValue('');
        },
        [mimeTypes, onFileSelected, onInvalidFileSelected]
    );

    const onFileInputChange = useCallback(() => {
        onFileDropped(fileInputRef.current.files);
    }, [onFileDropped]);

    return rc(
        Container,
        { id, isActive },
        rc(FileInput, {
            onChange: onFileInputChange,
            ref: fileInputRef,
            type: 'file',
            className: 'hidden',
            multiple: allowMultiple,
            accept: mimeTypes.join(','),
            value
        }),
        rc(
            FileDrop,
            {
                onFrameDragEnter: activate,
                onFrameDragLeave: deactivate,
                onFrameDrop: deactivate,
                // onDragOver: event => console.log('onDragOver', event),
                // onDragLeave: event => console.log('onDragLeave', event),
                onDrop: onFileDropped,
                onTargetClick
            },
            rc(HintText, null, title)
        )
    );
};

DropZone.defaultProps = {
    title: 'Click to select, or drag & drop file to upload.',
    mimeTypes: ['.csv']
};

DropZone.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    mimeTypes: PropTypes.arrayOf(PropTypes.string),
    onFileSelected: PropTypes.func,
    onInvalidFileSelected: PropTypes.func
};
export default DropZone;
