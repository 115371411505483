/* eslint camelcase: 0 */
// Because of the fsm naming conventions, ignore camelcase in this file.

// A default context for visualization tool - *overridden by app*.
// eslint-disable-next-line no-unused-vars
import * as Actions from './actions';
import * as Services from './services';
import * as Helpers from './helpers/index';

export const actions = Actions;
export const services = Services;
export const helpers = Helpers;

// Example context
export const context = {
    // This is created by the navigator.serviceWorker.register method inside svc_registerSw
    registration: undefined,
    // Errors should be stored in the context
    errors: [],
    // Pass in the event sink
    eventSink: { publish: () => {}, subscribe: () => {} },
    // normal package version number for the running index.html
    clientVersion: undefined
};

export const guards = {};

export const fsmConfig = {
    id: 'installFsm',
    initial: 'waitingForInitialRender',
    context,
    states: {
        waitingForInitialRender: {
            invoke: {
                id: 'waitForInitialRender',
                src: 'svc_waitForInitialRender',
                onDone: 'registering',
                onError: {
                    target: 'failedToRegister'
                }
            }
        },
        checkingForNewVersion: {
            invoke: {
                id: 'updateSw',
                src: 'svc_updateSw',
                onDone: 'registered.checkingServiceWorkerState',
                onError: {
                    // TODO: Maybe we lost connection.  This will retry on next refresh or NEW_VERSION_ANNOUNCEMENT
                    //  which is probably fine, but maybe in the future implement a retry.
                    target: 'redundant'
                }
            }
        },
        registering: {
            invoke: {
                id: 'registerSw',
                src: 'svc_registerSw',
                onDone: 'registered.checkingServiceWorkerState',
                onError: {
                    target: 'failedToRegister'
                }
            }
        },
        registered: {
            invoke: {
                id: 'monitorInstallation',
                src: 'svc_monitorInstallation'
            },
            on: {
                INSTALLING: '.installing',
                INSTALLED: '.waiting',
                ACTIVATED: 'reloadIfRequired',
                REDUNDANT: 'redundant',
                ERROR: {
                    target: 'redundant',
                    actions: 'do_storeError'
                }
            },
            states: {
                checkingServiceWorkerState: {},
                installing: {},
                waiting: {
                    initial: 'checkHttpQueueOnInterval',
                    states: {
                        checkHttpQueueOnInterval: {
                            invoke: {
                                id: 'checkHttpQueueOnInterval',
                                src: 'svc_checkHttpQueueOnInterval',
                                onDone: 'requestingReload',
                                onError: {
                                    target: '#installFsm.redundant',
                                    actions: 'do_storeError'
                                }
                            }
                        },
                        checkingIfServiceWorkerNewerThanClient: {
                            invoke: {
                                id: 'isServiceWorkerDifferentVersionThanClient',
                                src: 'svc_isServiceWorkerDifferentVersionThanClient',
                                onDone: [
                                    {
                                        // SW newer than client
                                        cond: (context, event) => event.data,
                                        target: 'requestingReload'
                                    },
                                    {
                                        // Same version - go ahead and activate
                                        target: 'activatingNewInstall'
                                    }
                                ],
                                onError: {
                                    target: '#installFsm.redundant',
                                    actions: 'do_storeError'
                                }
                            }
                        },
                        requestingReload: {
                            invoke: {
                                id: 'requestReload',
                                src: 'svc_requestReload',
                                onDone: {
                                    target: 'purgingData',
                                    actions: 'do_updateProgress'
                                },
                                onError: '#installFsm.redundant'
                            }
                        },
                        purgingData: {
                            invoke: {
                                id: 'requestDataPurge',
                                src: 'svc_requestDataPurge'
                            },
                            on: {
                                PURGE_FINISHED: {
                                    target: 'activatingNewInstall',
                                    actions: ['do_requireReload', 'do_updateProgress']
                                },
                                PURGE_FAILED: {
                                    target: '#installFsm.redundant',
                                    actions: 'do_storeError'
                                }
                            }
                        },
                        activatingNewInstall: {
                            entry: 'do_activateNewInstall'
                        }
                    }
                }
            }
        },
        reloadIfRequired: {
            invoke: {
                id: 'reloadSvc',
                src: 'svc_reloadCheck'
            },
            on: {
                REQUIRED: {
                    actions: ['do_acknowledgeReload', 'do_reload']
                },
                UNNECESSARY: {
                    target: 'activated'
                }
            }
        },
        activated: {
            entry: 'do_updateProgress',
            invoke: {
                id: 'monitorInstallation',
                src: 'svc_monitorInstallation'
            },
            on: {
                INSTALLING: {
                    target: 'registered.installing'
                },
                INSTALLED: {
                    target: 'registered.waiting'
                },
                NEW_VERSION_ANNOUNCEMENT: {
                    target: 'checkingForNewVersion'
                }
            }
        },
        redundant: {
            entry: ['do_reportAnyErrors', 'do_resetProgress'],
            on: {
                NEW_VERSION_ANNOUNCEMENT: {
                    target: 'checkingForNewVersion'
                }
            }
        },
        failedToRegister: {
            // Not much we can do.  Either the app failed to render or the service worker
            // failed to register.
            // TODO: Can/should we do a scorched earth approach here and blow away all caches and try
            // to reload the page?  Future work item?
            entry: 'do_reportAnyErrors',
            type: 'final'
        }
    }
};

export default fsmConfig;
