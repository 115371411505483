// reduce bundle size by not getting this from lib_ui_services
import localforage from 'localforage';
import { DB_NAME } from '../../constants';

let _getKey, _setKey, _deleteKey;

// eslint-disable-next-line no-undef
if (__UNIT_TESTING__) {
    const memCache = {};
    _getKey = async function (key) {
        return Promise.resolve(memCache[key]);
    };

    _setKey = async function (key, value) {
        memCache[key] = value;
        return Promise.resolve();
    };

    _deleteKey = async function (key) {
        delete memCache[key];
        return Promise.resolve();
    };
} else {
    const store = localforage.createInstance({ name: DB_NAME });

    _getKey = async function (key) {
        return await store.getItem(key);
    };

    _setKey = async function (key, value) {
        return await store.setItem(key, value);
    };

    _deleteKey = async function (key) {
        return await store.removeItem(key);
    };
}

export const getKey = _getKey;
export const setKey = _setKey;
export const deleteKey = _deleteKey;
