import { getKey } from './localStorage';
import { VERSION_KEY } from '../../constants';
export const _private = { getKey };
export default async function getLatestServiceWorkerVersions() {
    try {
        const versions = await _private.getKey(VERSION_KEY);
        return versions || {};
    } catch (err) {
        return {};
    }
}
