import getLatestServiceWorkerVersions from './getLatestServiceWorkerVersions';
import isSemVerDifferent from './isSemVerDifferent';

const _p = {
    getLatestServiceWorkerVersions,
    isSemVerDifferent
};
export const _private = _p;

export default async function isServiceWorkerVersionDifferentThanClient(clientVersion) {
    const versions = await _p.getLatestServiceWorkerVersions();
    const newVersion = versions.installing || versions.waiting;
    return newVersion != null && _p.isSemVerDifferent(newVersion, clientVersion);
}
