/* eslint camelcase: 0 */
import isServiceWorkerVersionDifferentThanClient from './helpers/isServiceWorkerVersionDifferentThanClient';
import { assign } from 'xstate';
import logging from '@sstdev/lib_logging';
import { SERVICE_WORKER_PATH } from '../constants';

export const do_storeError = assign((context, event) => {
    let err = event.data || event.payload;
    if (err == null || err.message == null) {
        err = {
            message: 'An error storage was requested, but no error message was provided.'
        };
    }
    return {
        errors: [...context.errors, err]
    };
});

export const do_resetProgress = context => {
    const { eventSink } = context;
    eventSink.publish(
        { mainTitle: 'Update Progress' },
        { verb: 'reset', namespace: 'application', relation: 'progress' }
    );
};

export const do_requireReload = assign(() => {
    return { reloadRequired: true };
});
export const do_acknowledgeReload = assign(() => {
    return { reloadRequired: false };
});

const _p = {
    isServiceWorkerVersionDifferentThanClient
};

export const _private = _p;

export const do_updateProgress = async (context, event) => {
    const { eventSink, clientVersion } = context;
    const total = 3;
    let current = 0;
    switch (event.type) {
        case 'UNNECESSARY':
            return;
        case 'done.invoke.requestReload':
            current = 1;
            break;
        case 'PURGE_FINISHED':
            current = 2;
            break;
        case 'ACTIVATED': {
            const isNew = await _p.isServiceWorkerVersionDifferentThanClient(clientVersion);
            // Don't create/update progress dialog if this is not
            // a new version
            if (!isNew) {
                // If, somehow, a new version is activated, don't leave the dialog.
                eventSink.publish(
                    { mainTitle: 'Updating the Application' },
                    { verb: 'reset', namespace: 'application', relation: 'progress' }
                );
                return;
            }
            current = 3;
            break;
        }
        default:
            throw new Error(event.type + ' not implemented');
    }
    eventSink.publish(
        {
            mainTitle: 'Updating the Application',
            description: 'The page will reload when finished.',
            title: 'Update Progress',
            current,
            total
        },
        { verb: 'update', namespace: 'application', relation: 'progress' }
    );
};

export const do_reload = async () => {
    // This library won't be used by react native so the lack of abstraction
    // isn't a problem here
    window.location.href = window.location.pathname;
};

export const do_reportAnyErrors = assign(context => {
    const { errors } = context;
    errors &&
        errors.forEach(err => {
            logging.error(err);
        });
    return { errors: [] };
});

export const do_activateNewInstall = assign(async context => {
    const { errors } = context;
    try {
        const registration = await navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH);
        // Now ask the service worker to skip waiting and activate.
        const serviceWorker = registration.installing || registration.waiting || registration.active;
        serviceWorker.postMessage(JSON.stringify({ type: 'readyForActivation' }));
    } catch (err) {
        return {
            errors: [...errors, err]
        };
    }
});
