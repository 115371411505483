import Installer, { services, actions, guards, helpers } from 'lib_ui-service-worker';
import { Machine, interpret } from 'xstate';
import logging from '@sstdev/lib_logging';
import getGlobalConfig from '../globalConfig';
const { getLatestServiceWorkerVersions } = helpers;

// The service worker installer workflow will not actually start until
// the UI is ready.
// (see lib_ui-service-worker\src\installer\services.js svc_waitForInitialRender())
export default function register(eventSink) {
    const { brand: theme, isDeployed, groupNumber } = getGlobalConfig();
    // Skip service worker if you are not testing it and you
    // are in dev mode.
    // More info:  in webpack, __TESTING_SERVICE_WORKER__ = true
    // ensures you get a new version number each time it builds the
    // service worker - otherwise you get inconsistent results during
    // the installation.
    // eslint-disable-next-line no-undef
    if (!isDeployed && !__TESTING_SERVICE_WORKER__) return;
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`/g/${groupNumber}/redirectWorker.js`, { scope: '/' });
        // Create our machine, merging in our code modules (services, actions, guards)
        const machine = Machine(Installer, { services, actions, guards });
        const [subscribe, publish] = eventSink;
        // Add our context to the machine (overrides default context)
        const machineWithInitialContext = machine.withContext({
            eventSink: { subscribe, publish },
            // eslint-disable-next-line no-undef
            clientVersion: __PACKAGE_VERSION__,
            groupNumber,
            theme
        });

        // Interpret machine.  See https://xstate.js.org/docs/guides/interpretation.html#interpreter
        // eslint-disable-next-line no-undef
        const installer = interpret(machineWithInitialContext, { devTools: __TESTING_SERVICE_WORKER__ });
        // eslint-disable-next-line no-undef
        if (!__PRODUCTION__ && __TESTING_SERVICE_WORKER__) {
            installer.onTransition(async state => {
                const versions = await getLatestServiceWorkerVersions();
                logging.debug('SW STATE: ', state.value, ' | VERSIONS: ', versions);
            });
        }
        subscribe({ verb: 'create', namespace: 'deploy', relation: 'release', status: 'success' }, () => {
            installer.send('NEW_VERSION_ANNOUNCEMENT');
        });

        installer.start();
    }
}
