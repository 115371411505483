/* eslint camelcase: 0 */
import getLatestServiceWorkerVersions from './helpers/getLatestServiceWorkerVersions';
import logging from '@sstdev/lib_logging';
import isServiceWorkerVersionDifferentThanClient from './helpers/isServiceWorkerVersionDifferentThanClient';
import { SERVICE_WORKER_PATH } from '../constants';
/**
 * Use Xstate [Promise Service](https://xstate.js.org/docs/guides/communication.html#invoking-promises)
 * to register the service worker.
 * @param context
 * @param event
 */
export function svc_registerSw() {
    logging.debug(`[SERVICEWORKER] registering at ${SERVICE_WORKER_PATH}`);
    return navigator.serviceWorker.register(SERVICE_WORKER_PATH);
}

/**
 * Use Xstate [Promise Service](https://xstate.js.org/docs/guides/communication.html#invoking-promises)
 * to update the service worker with a new version.
 * @param context
 * @param event
 */
export async function svc_updateSw() {
    const registration = await navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH);
    return registration.update();
}

let _p = {
    listenToWorker,
    onRegistrationUpdateFound,
    isServiceWorkerVersionDifferentThanClient
};
export const _private = _p;
// Listener for changes to service worker state
async function listenToWorker(serviceWorker, unsubscribes, callback) {
    if (!serviceWorker) {
        return callback({
            type: 'ERROR',
            data: new Error('Unable to get the service worker that should be installing right now.')
        });
    }

    const reportState = async () => {
        const versions = await getLatestServiceWorkerVersions();
        callback({ type: serviceWorker.state.toUpperCase(), data: { versions } });
        logging.debug('SW STATE: ', serviceWorker.state, ' | VERSIONS: ', versions);
    };
    // Let the state machine know the current service worker state.
    await reportState();

    // Now that we have the ingredients, add them to cleanupRefs
    unsubscribes.push(() => serviceWorker.removeEventListener('statechange', reportState));
    // Now do it any time the state changes
    serviceWorker.addEventListener('statechange', reportState);
}

// Listener for new service workers on registration.
async function onRegistrationUpdateFound(registration, unsubscribe, unsubscribes, callback) {
    // Remove any previous listeners before adding new ones
    unsubscribe();
    // One of these should have a service worker. Prefer the newest.
    const latestWorker = registration.installing || registration.waiting || registration.active;
    await _p.listenToWorker(latestWorker, unsubscribes, callback);
    // We might get a new service worker from the server
    const listener = () => onRegistrationUpdateFound(registration, unsubscribe, unsubscribes, callback);
    unsubscribes.push(() => registration.removeEventListener('updatefound', listener));
    registration.addEventListener('updatefound', listener);
}

/**
 * Use the XState [Callback Service]{@link: https://xstate.js.org/docs/guides/communication.html#invoking-callbacks}
 * approach to monitor service worker states over time.
 * @param context
 * @param event
 */
export const svc_monitorInstallation = () => callback => {
    // Create a reference to hold the cleanup function
    const unsubscribes = [];
    // Clear the unsubscribes array as we unsubscribe each
    const unsubscribe = () => {
        let u;
        while ((u = unsubscribes.pop())) {
            u();
        }
    };

    // You cannot use `async/await` here because you need to return the cleanup function.
    navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH).then(registration => {
        if (!registration) {
            return callback({
                type: 'ERROR',
                data: new Error('The service worker registration is not available.')
            });
        }

        // Directly call the listener for the initial registration.
        _p.onRegistrationUpdateFound(registration, unsubscribe, unsubscribes, callback);
    });

    // When this callback service shuts down, cleanup if we've created listeners
    return unsubscribe;
};

/**
 * Use the XState [Callback Service]{@link: https://xstate.js.org/docs/guides/communication.html#invoking-callbacks}
 * approach to initiate and monitor a useCase database purge.
 * @param context
 * @param event
 */
export const svc_requestDataPurge = context => callback => {
    const { eventSink } = context;
    const unsubscribeSuccess = eventSink.subscribe(
        { verb: 'purge', namespace: 'application', relation: 'useCase', status: 'success' },
        () => {
            callback('PURGE_FINISHED');
        }
    );
    const unsubscribeFailure = eventSink.subscribe(
        { verb: 'purge', namespace: 'application', relation: 'useCase', status: 'failure' },
        () => {
            callback('PURGE_FAILED');
        }
    );

    eventSink.publish({}, { verb: 'purge', namespace: 'application', relation: 'useCase', type: 'all' });

    // cleanup
    return () => {
        unsubscribeSuccess();
        unsubscribeFailure();
    };
};

export const svc_reloadCheck = context => async callback => {
    const shouldRestart = await _p.isServiceWorkerVersionDifferentThanClient(context.clientVersion);
    if (context.reloadRequired || shouldRestart) {
        callback('REQUIRED');
    } else {
        callback('UNNECESSARY');
    }
};

export const svc_requestReload = context => {
    return new Promise((resolve, reject) => {
        const { eventSink } = context;
        eventSink.publish(
            {
                message: 'New features or improvements are available.  Would you like to update now?',
                okButtonText: 'YES',
                cancelButtonText: 'NO',
                okAction: () => {
                    resolve();
                },
                cancelAction: () => {
                    reject();
                }
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
};

export const svc_waitForInitialRender = context => {
    return new Promise((resolve, reject) => {
        let timeoutRef;
        const unsubscribeSuccess = context.eventSink.subscribe(
            { verb: 'change', namespace: 'application', relation: 'runtime' },
            payload => {
                if (payload.initialRenderComplete) {
                    clearTimeout(timeoutRef);
                    unsubscribeSuccess();
                    resolve();
                }
            }
        );
        timeoutRef = setTimeout(() => {
            reject(new Error('The service worker installation timed out while waiting for an initial render'));
        }, context.initialRenderTimeout || 60000);
    });
};

// get_application_httpqueue
export const svc_checkHttpQueueOnInterval = context => {
    return new Promise((resolve, reject) => {
        let retry = 0;
        const { eventSink, httpQueueLookupRetryInterval } = context;
        const unsubscribeSuccess = eventSink.subscribe(
            { verb: 'get', namespace: 'application', relation: 'httpqueue', status: 'success' },
            payload => {
                if (payload.queueCount === 0) {
                    unsubscribe();
                    resolve();
                } else {
                    retry++;
                    setTimeout(requestQueue, httpQueueLookupRetryInterval || 3000);
                }
            }
        );
        const unsubscribeFailure = eventSink.subscribe(
            { verb: 'get', namespace: 'application', relation: 'httpqueue', status: 'failure' },
            () => {
                retry++;
                setTimeout(requestQueue, httpQueueLookupRetryInterval || 3000);
            }
        );

        function requestQueue() {
            if (retry > 5) {
                unsubscribe();
                reject(new Error('Unable to clear the http transaction queue.'));
            }
            eventSink.publish({}, { verb: 'get', namespace: 'application', relation: 'httpqueue' });
        }

        requestQueue();

        // cleanup
        function unsubscribe() {
            unsubscribeSuccess();
            unsubscribeFailure();
        }
    });
};

export const svc_isServiceWorkerDifferentVersionThanClient = context => {
    return _p.isServiceWorkerVersionDifferentThanClient(context.clientVersion);
};
