export default function registerRedirectWorker() {
    let root = '/';
    let group;
    const hasGroup = /\/g\/(\d+).*/.test(self.location.pathname);
    if (hasGroup) {
        const result = self.location.pathname.match(/\/g\/(\d+).*/);
        group = result[1];
        root = `/g/${group}/`;
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(`${root}redirectWorker.js`, { scope: '/' });
        }
    }
}
